import Layout from "layout";
import React, { ReactElement, useEffect, useState } from "react";
import { formatPrice } from "utils/helpers";
import { LiveApi, Product } from "../api/sdk";
import {
  BasePageContext,
  TemplateHeadProps,
  TemplatePageProps,
} from "utils/types";
import { Link } from "gatsby";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import SEO from "components/SEO";

export interface ProductDetailPageContext extends BasePageContext {
  product: Product;
}

const htHomeImg = require("images/hillary-taylor-home.jpg").default;

export default (
  props: TemplatePageProps<ProductDetailPageContext>
): ReactElement => {
  const { product, appData } = props.pageContext;

  const [qty, setQty] = useState(1);
  const [currentInventory, setCurrentInventory] = useState<
    number | null | undefined
  >();

  const tag = product.tags.length ? appData.tags.find(tag => tag._id === product.tags[0]) : undefined

  useEffect(() => {
    const api = new LiveApi("https://api.hillarytaylorinteriors.com/");

    api.product
      .detail(product._id)
      .then((p) => {
        console.log("test")
        console.log(p.inventory)
        console.log("test")
        setCurrentInventory(Math.max(p.inventory, 0))}
        )
      .catch((e) => {
        console.error(e);
        setCurrentInventory(null);
      });
  }, []);

  const isInvLoading = currentInventory === undefined;
  const isInvError = currentInventory === null;
  const isOutOfStock = currentInventory === 0;
  const isLoadedInv = !isInvLoading && !isInvError;

  // const [added, setAdded] = useState(false);
  // const dependencyArray = [];
  // if (typeof window !== "undefined") {
  //   dependencyArray.push(window?.Snipcart);
  // }

  // useEffect(() => {
  //   if (typeof window === "undefined") {
  //     return;
  //   }

  //   const unsubscribe = window.Snipcart.events.on("item.added", () => {
  //     setAdded(true);
  //     setTimeout(() => setAdded(false), 3000);
  //   });
  //   return unsubscribe;
  // }, dependencyArray);

  return (
    <Layout bodyClass="capsule-single" appData={appData}>
      <div className="container--large spaced-50">
        <p className="breadcrumbs">
          <Link to="/products">Products</Link> <span>/</span>{" "}
          <span className="current">{product.name}</span>
        </p>
        <div
          style={{
            display: "flex",
            gap: "3rem",
            flexWrap: "wrap",
          }}
        >
          <div style={{ maxWidth: "100%", margin: "auto" }}>
            <div
              style={{
                flexBasis: 1,
                flexGrow: 1,
                maxWidth: 500,
                margin: "auto",
              }}
            >
              <Carousel
                swipeable
                emulateTouch
                infiniteLoop
                thumbWidth={60}
                showIndicators={false}
                showArrows={false}
                showStatus={false}
              >
                {product.images.map((image, i) => (
                  <div key={i}>
                    <img src={image} alt={`${product.name} image ${i}`} />
                  </div>
                ))}
              </Carousel>
            </div>
          </div>

          <div
            className="capsule-meta"
            style={{ flexBasis: 1, flexGrow: 1, minWidth: 300 }}
          >
            <h1>{product.name}</h1>

            <h3 className="price-range">{formatPrice(product.price)}</h3>

            {tag?.description && (
              <div
                dangerouslySetInnerHTML={{ __html: tag.description }}
                className="product-tag-description"
              />
            )}

            <div
              dangerouslySetInnerHTML={{ __html: product.description }}
              className="product-page-description"
            />

            <hr />

            <div style={{ maxWidth: 150 }}>
              {isInvLoading && <p style={{ marginTop: 50 }}>Loading...</p>}

              {isOutOfStock && (
                <p
                  style={{
                    marginTop: 50,
                    fontWeight: "bold",
                    fontSize: "1.2rem",
                  }}
                >
                  This item is out of stock
                </p>
              )}

              {!isInvLoading && !isOutOfStock && (
                <div className="product-options">
                  <label className="product-quantity">
                    Quantity
                    <br />
                    <input
                      min="1"
                      max={currentInventory ?? undefined}
                      type="number"
                      value={qty}
                      onChange={(e) => setQty(+e.target.value)}
                    />
                  </label>
                  {isLoadedInv && <span>{currentInventory} - Remaining</span>}
                </div>
              )}

              <button
                className="button blue full-width snipcart-add-item"
                style={{ display: isInvLoading || isOutOfStock ? "none" : "block" }}
                data-item-id={product._id}
                data-item-name={product.name}
                data-item-price={product.price * 0.01}
                data-item-url={`/products/${product.slug}`}
                data-item-image={product.images[0]}
                data-item-description={product.shortDescription}
                data-item-quantity={qty}
                data-item-max-quantity={
                  isLoadedInv ? currentInventory : undefined
                }
                data-item-shippable={true}
              >
                Add to Cart
              </button>
            </div>

            {/* {added && (
              <div className="added-to-cart-message">Added to cart</div>
            )} */}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const Head = (props: TemplateHeadProps<ProductDetailPageContext>) => {
  const { product } = props.pageContext;

  return (
    <SEO
      title="Home"
      ogMeta={{
        title: `${product.name} | Hillary Taylor`,
        description: product.shortDescription,
        image: product?.images[0] ?? htHomeImg,
      }}
    ></SEO>
  );
};
