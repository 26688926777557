import { Query, MassModification, EntryChange, ListChange, Modification, Condition, GroupCountQuery, AggregateQuery, GroupAggregateQuery, Aggregate, apiCall, Path } from '@lightningkite/lightning-server-simplified'

export interface BlogItem {
    text: string
    link: string
    image: string
}
export interface BlogPost {
    _id: string
    slug: string
    isPublished: boolean
    created: string
    revisions: Array<BlogPost>
    title: string
    headImage: string
    body: string
    tailImage: string | null | undefined
    postItems: Array<BlogItem>
}
export interface ContactSubmission {
    _id: string
    name: string
    email: string
    subject: string
    body: string
}
export interface HealthStatus {
    level: Level
    checkedAt: string
    additionalMessage: string | null | undefined
}
export enum Level {
    OK = "OK",
    WARNING = "WARNING",
    URGENT = "URGENT",
    ERROR = "ERROR",
}
export interface Memory {
    max: number
    total: number
    free: number
    systemAllocated: number
    usage: number
}
export interface PortFolioEntry {
    _id: string
    slug: string
    name: string
    isPublished: boolean
    inProgress: boolean
    thumbnail: string
    images: Array<string>
    order: number
}
export interface PressRelease {
    _id: string
    publicationName: string
    year: number
    season: Season
    issue: string | null | undefined
    externalLink: string | null | undefined
    cover: string
    order: number
}
export interface Product {
    _id: string
    slug: string
    name: string
    brand: string | null | undefined
    images: Array<string>
    tags: Array<string>
    inventory: number
    price: number
    description: string
    shortDescription: string
    isPublished: boolean
    onSale: string
    order: number
}
export enum Season {
    Spring = "Spring",
    Summer = "Summer",
    Fall = "Fall",
    Winter = "Winter",
}
export interface ServerHealth {
    serverId: string
    version: string
    memory: Memory
    features: Record<string, HealthStatus>
    loadAverageCpu: number
}
export interface Tag {
    _id: string
    slug: string
    name: string
    description: string | null | undefined
}
export interface UploadInformation {
    uploadUrl: string
    futureCallToken: string
}
export interface User {
    _id: string
    email: string
    isSuperAdmin: boolean
}



export interface Api {
    readonly auth: {
        refreshToken(userToken: string): Promise<string>
        getSelf(userToken: string): Promise<User>
        emailLoginLink(input: string): Promise<void>
    }
    readonly product: {
        default(userToken?: string): Promise<Product>
        query(input: Query<Product>, userToken?: string): Promise<Array<Product>>
        detail(id: string, userToken?: string): Promise<Product>
        insertBulk(input: Array<Product>, userToken?: string): Promise<Array<Product>>
        insert(input: Product, userToken?: string): Promise<Product>
        upsert(id: string, input: Product, userToken?: string): Promise<Product>
        bulkReplace(input: Array<Product>, userToken?: string): Promise<Array<Product>>
        replace(id: string, input: Product, userToken?: string): Promise<Product>
        bulkModify(input: MassModification<Product>, userToken?: string): Promise<number>
        modifyWithDiff(id: string, input: Modification<Product>, userToken?: string): Promise<EntryChange<Product>>
        modify(id: string, input: Modification<Product>, userToken?: string): Promise<Product>
        bulkDelete(input: Condition<Product>, userToken?: string): Promise<number>
        delete(id: string, userToken?: string): Promise<void>
        count(input: Condition<Product>, userToken?: string): Promise<number>
        groupCount(input: GroupCountQuery<Product>, userToken?: string): Promise<Record<string, number>>
        aggregate(input: AggregateQuery<Product>, userToken?: string): Promise<number | null | undefined>
        groupAggregate(input: GroupAggregateQuery<Product>, userToken?: string): Promise<Record<string, number | null | undefined>>
    }
    readonly user: {
        default(userToken?: string): Promise<User>
        query(input: Query<User>, userToken?: string): Promise<Array<User>>
        detail(id: string, userToken?: string): Promise<User>
        insertBulk(input: Array<User>, userToken?: string): Promise<Array<User>>
        insert(input: User, userToken?: string): Promise<User>
        upsert(id: string, input: User, userToken?: string): Promise<User>
        bulkReplace(input: Array<User>, userToken?: string): Promise<Array<User>>
        replace(id: string, input: User, userToken?: string): Promise<User>
        bulkModify(input: MassModification<User>, userToken?: string): Promise<number>
        modifyWithDiff(id: string, input: Modification<User>, userToken?: string): Promise<EntryChange<User>>
        modify(id: string, input: Modification<User>, userToken?: string): Promise<User>
        bulkDelete(input: Condition<User>, userToken?: string): Promise<number>
        delete(id: string, userToken?: string): Promise<void>
        count(input: Condition<User>, userToken?: string): Promise<number>
        groupCount(input: GroupCountQuery<User>, userToken?: string): Promise<Record<string, number>>
        aggregate(input: AggregateQuery<User>, userToken?: string): Promise<number | null | undefined>
        groupAggregate(input: GroupAggregateQuery<User>, userToken?: string): Promise<Record<string, number | null | undefined>>
    }
    readonly tag: {
        default(userToken?: string): Promise<Tag>
        query(input: Query<Tag>, userToken?: string): Promise<Array<Tag>>
        detail(id: string, userToken?: string): Promise<Tag>
        insertBulk(input: Array<Tag>, userToken?: string): Promise<Array<Tag>>
        insert(input: Tag, userToken?: string): Promise<Tag>
        upsert(id: string, input: Tag, userToken?: string): Promise<Tag>
        bulkReplace(input: Array<Tag>, userToken?: string): Promise<Array<Tag>>
        replace(id: string, input: Tag, userToken?: string): Promise<Tag>
        bulkModify(input: MassModification<Tag>, userToken?: string): Promise<number>
        modifyWithDiff(id: string, input: Modification<Tag>, userToken?: string): Promise<EntryChange<Tag>>
        modify(id: string, input: Modification<Tag>, userToken?: string): Promise<Tag>
        bulkDelete(input: Condition<Tag>, userToken?: string): Promise<number>
        delete(id: string, userToken?: string): Promise<void>
        count(input: Condition<Tag>, userToken?: string): Promise<number>
        groupCount(input: GroupCountQuery<Tag>, userToken?: string): Promise<Record<string, number>>
        aggregate(input: AggregateQuery<Tag>, userToken?: string): Promise<number | null | undefined>
        groupAggregate(input: GroupAggregateQuery<Tag>, userToken?: string): Promise<Record<string, number | null | undefined>>
    }
    readonly blogPost: {
        default(userToken?: string): Promise<BlogPost>
        query(input: Query<BlogPost>, userToken?: string): Promise<Array<BlogPost>>
        detail(id: string, userToken?: string): Promise<BlogPost>
        insertBulk(input: Array<BlogPost>, userToken?: string): Promise<Array<BlogPost>>
        insert(input: BlogPost, userToken?: string): Promise<BlogPost>
        upsert(id: string, input: BlogPost, userToken?: string): Promise<BlogPost>
        bulkReplace(input: Array<BlogPost>, userToken?: string): Promise<Array<BlogPost>>
        replace(id: string, input: BlogPost, userToken?: string): Promise<BlogPost>
        bulkModify(input: MassModification<BlogPost>, userToken?: string): Promise<number>
        modifyWithDiff(id: string, input: Modification<BlogPost>, userToken?: string): Promise<EntryChange<BlogPost>>
        modify(id: string, input: Modification<BlogPost>, userToken?: string): Promise<BlogPost>
        bulkDelete(input: Condition<BlogPost>, userToken?: string): Promise<number>
        delete(id: string, userToken?: string): Promise<void>
        count(input: Condition<BlogPost>, userToken?: string): Promise<number>
        groupCount(input: GroupCountQuery<BlogPost>, userToken?: string): Promise<Record<string, number>>
        aggregate(input: AggregateQuery<BlogPost>, userToken?: string): Promise<number | null | undefined>
        groupAggregate(input: GroupAggregateQuery<BlogPost>, userToken?: string): Promise<Record<string, number | null | undefined>>
    }
    readonly portFolioEntry: {
        default(userToken?: string): Promise<PortFolioEntry>
        query(input: Query<PortFolioEntry>, userToken?: string): Promise<Array<PortFolioEntry>>
        detail(id: string, userToken?: string): Promise<PortFolioEntry>
        insertBulk(input: Array<PortFolioEntry>, userToken?: string): Promise<Array<PortFolioEntry>>
        insert(input: PortFolioEntry, userToken?: string): Promise<PortFolioEntry>
        upsert(id: string, input: PortFolioEntry, userToken?: string): Promise<PortFolioEntry>
        bulkReplace(input: Array<PortFolioEntry>, userToken?: string): Promise<Array<PortFolioEntry>>
        replace(id: string, input: PortFolioEntry, userToken?: string): Promise<PortFolioEntry>
        bulkModify(input: MassModification<PortFolioEntry>, userToken?: string): Promise<number>
        modifyWithDiff(id: string, input: Modification<PortFolioEntry>, userToken?: string): Promise<EntryChange<PortFolioEntry>>
        modify(id: string, input: Modification<PortFolioEntry>, userToken?: string): Promise<PortFolioEntry>
        bulkDelete(input: Condition<PortFolioEntry>, userToken?: string): Promise<number>
        delete(id: string, userToken?: string): Promise<void>
        count(input: Condition<PortFolioEntry>, userToken?: string): Promise<number>
        groupCount(input: GroupCountQuery<PortFolioEntry>, userToken?: string): Promise<Record<string, number>>
        aggregate(input: AggregateQuery<PortFolioEntry>, userToken?: string): Promise<number | null | undefined>
        groupAggregate(input: GroupAggregateQuery<PortFolioEntry>, userToken?: string): Promise<Record<string, number | null | undefined>>
    }
    readonly contactSubmission: {
        default(userToken?: string): Promise<ContactSubmission>
        query(input: Query<ContactSubmission>, userToken?: string): Promise<Array<ContactSubmission>>
        detail(id: string, userToken?: string): Promise<ContactSubmission>
        insertBulk(input: Array<ContactSubmission>, userToken?: string): Promise<Array<ContactSubmission>>
        insert(input: ContactSubmission, userToken?: string): Promise<ContactSubmission>
        upsert(id: string, input: ContactSubmission, userToken?: string): Promise<ContactSubmission>
        bulkReplace(input: Array<ContactSubmission>, userToken?: string): Promise<Array<ContactSubmission>>
        replace(id: string, input: ContactSubmission, userToken?: string): Promise<ContactSubmission>
        bulkModify(input: MassModification<ContactSubmission>, userToken?: string): Promise<number>
        modifyWithDiff(id: string, input: Modification<ContactSubmission>, userToken?: string): Promise<EntryChange<ContactSubmission>>
        modify(id: string, input: Modification<ContactSubmission>, userToken?: string): Promise<ContactSubmission>
        bulkDelete(input: Condition<ContactSubmission>, userToken?: string): Promise<number>
        delete(id: string, userToken?: string): Promise<void>
        count(input: Condition<ContactSubmission>, userToken?: string): Promise<number>
        groupCount(input: GroupCountQuery<ContactSubmission>, userToken?: string): Promise<Record<string, number>>
        aggregate(input: AggregateQuery<ContactSubmission>, userToken?: string): Promise<number | null | undefined>
        groupAggregate(input: GroupAggregateQuery<ContactSubmission>, userToken?: string): Promise<Record<string, number | null | undefined>>
    }
    readonly pressRelease: {
        default(userToken?: string): Promise<PressRelease>
        query(input: Query<PressRelease>, userToken?: string): Promise<Array<PressRelease>>
        detail(id: string, userToken?: string): Promise<PressRelease>
        insertBulk(input: Array<PressRelease>, userToken?: string): Promise<Array<PressRelease>>
        insert(input: PressRelease, userToken?: string): Promise<PressRelease>
        upsert(id: string, input: PressRelease, userToken?: string): Promise<PressRelease>
        bulkReplace(input: Array<PressRelease>, userToken?: string): Promise<Array<PressRelease>>
        replace(id: string, input: PressRelease, userToken?: string): Promise<PressRelease>
        bulkModify(input: MassModification<PressRelease>, userToken?: string): Promise<number>
        modifyWithDiff(id: string, input: Modification<PressRelease>, userToken?: string): Promise<EntryChange<PressRelease>>
        modify(id: string, input: Modification<PressRelease>, userToken?: string): Promise<PressRelease>
        bulkDelete(input: Condition<PressRelease>, userToken?: string): Promise<number>
        delete(id: string, userToken?: string): Promise<void>
        count(input: Condition<PressRelease>, userToken?: string): Promise<number>
        groupCount(input: GroupCountQuery<PressRelease>, userToken?: string): Promise<Record<string, number>>
        aggregate(input: AggregateQuery<PressRelease>, userToken?: string): Promise<number | null | undefined>
        groupAggregate(input: GroupAggregateQuery<PressRelease>, userToken?: string): Promise<Record<string, number | null | undefined>>
    }
    uploadFileForRequest(): Promise<UploadInformation>
    getServerHealth(userToken: string): Promise<ServerHealth>
}



export class UserSession {
    constructor(public api: Api, public userToken: string) {}
    getServerHealth(): Promise<ServerHealth> { return this.api.getServerHealth(this.userToken) } 
    uploadFileForRequest(): Promise<UploadInformation> { return this.api.uploadFileForRequest() } 
    readonly auth = {
        refreshToken: (): Promise<string> => { return this.api.auth.refreshToken(this.userToken) }, 
        getSelf: (): Promise<User> => { return this.api.auth.getSelf(this.userToken) }, 
        emailLoginLink: (input: string): Promise<void> => { return this.api.auth.emailLoginLink(input) }, 
    }
    readonly product = {
        default: (): Promise<Product> => { return this.api.product.default(this.userToken) }, 
        query: (input: Query<Product>): Promise<Array<Product>> => { return this.api.product.query(input, this.userToken) }, 
        detail: (id: string): Promise<Product> => { return this.api.product.detail(id, this.userToken) }, 
        insertBulk: (input: Array<Product>): Promise<Array<Product>> => { return this.api.product.insertBulk(input, this.userToken) }, 
        insert: (input: Product): Promise<Product> => { return this.api.product.insert(input, this.userToken) }, 
        upsert: (id: string, input: Product): Promise<Product> => { return this.api.product.upsert(id, input, this.userToken) }, 
        bulkReplace: (input: Array<Product>): Promise<Array<Product>> => { return this.api.product.bulkReplace(input, this.userToken) }, 
        replace: (id: string, input: Product): Promise<Product> => { return this.api.product.replace(id, input, this.userToken) }, 
        bulkModify: (input: MassModification<Product>): Promise<number> => { return this.api.product.bulkModify(input, this.userToken) }, 
        modifyWithDiff: (id: string, input: Modification<Product>): Promise<EntryChange<Product>> => { return this.api.product.modifyWithDiff(id, input, this.userToken) }, 
        modify: (id: string, input: Modification<Product>): Promise<Product> => { return this.api.product.modify(id, input, this.userToken) }, 
        bulkDelete: (input: Condition<Product>): Promise<number> => { return this.api.product.bulkDelete(input, this.userToken) }, 
        delete: (id: string): Promise<void> => { return this.api.product.delete(id, this.userToken) }, 
        count: (input: Condition<Product>): Promise<number> => { return this.api.product.count(input, this.userToken) }, 
        groupCount: (input: GroupCountQuery<Product>): Promise<Record<string, number>> => { return this.api.product.groupCount(input, this.userToken) }, 
        aggregate: (input: AggregateQuery<Product>): Promise<number | null | undefined> => { return this.api.product.aggregate(input, this.userToken) }, 
        groupAggregate: (input: GroupAggregateQuery<Product>): Promise<Record<string, number | null | undefined>> => { return this.api.product.groupAggregate(input, this.userToken) }, 
    }
    readonly user = {
        default: (): Promise<User> => { return this.api.user.default(this.userToken) }, 
        query: (input: Query<User>): Promise<Array<User>> => { return this.api.user.query(input, this.userToken) }, 
        detail: (id: string): Promise<User> => { return this.api.user.detail(id, this.userToken) }, 
        insertBulk: (input: Array<User>): Promise<Array<User>> => { return this.api.user.insertBulk(input, this.userToken) }, 
        insert: (input: User): Promise<User> => { return this.api.user.insert(input, this.userToken) }, 
        upsert: (id: string, input: User): Promise<User> => { return this.api.user.upsert(id, input, this.userToken) }, 
        bulkReplace: (input: Array<User>): Promise<Array<User>> => { return this.api.user.bulkReplace(input, this.userToken) }, 
        replace: (id: string, input: User): Promise<User> => { return this.api.user.replace(id, input, this.userToken) }, 
        bulkModify: (input: MassModification<User>): Promise<number> => { return this.api.user.bulkModify(input, this.userToken) }, 
        modifyWithDiff: (id: string, input: Modification<User>): Promise<EntryChange<User>> => { return this.api.user.modifyWithDiff(id, input, this.userToken) }, 
        modify: (id: string, input: Modification<User>): Promise<User> => { return this.api.user.modify(id, input, this.userToken) }, 
        bulkDelete: (input: Condition<User>): Promise<number> => { return this.api.user.bulkDelete(input, this.userToken) }, 
        delete: (id: string): Promise<void> => { return this.api.user.delete(id, this.userToken) }, 
        count: (input: Condition<User>): Promise<number> => { return this.api.user.count(input, this.userToken) }, 
        groupCount: (input: GroupCountQuery<User>): Promise<Record<string, number>> => { return this.api.user.groupCount(input, this.userToken) }, 
        aggregate: (input: AggregateQuery<User>): Promise<number | null | undefined> => { return this.api.user.aggregate(input, this.userToken) }, 
        groupAggregate: (input: GroupAggregateQuery<User>): Promise<Record<string, number | null | undefined>> => { return this.api.user.groupAggregate(input, this.userToken) }, 
    }
    readonly tag = {
        default: (): Promise<Tag> => { return this.api.tag.default(this.userToken) }, 
        query: (input: Query<Tag>): Promise<Array<Tag>> => { return this.api.tag.query(input, this.userToken) }, 
        detail: (id: string): Promise<Tag> => { return this.api.tag.detail(id, this.userToken) }, 
        insertBulk: (input: Array<Tag>): Promise<Array<Tag>> => { return this.api.tag.insertBulk(input, this.userToken) }, 
        insert: (input: Tag): Promise<Tag> => { return this.api.tag.insert(input, this.userToken) }, 
        upsert: (id: string, input: Tag): Promise<Tag> => { return this.api.tag.upsert(id, input, this.userToken) }, 
        bulkReplace: (input: Array<Tag>): Promise<Array<Tag>> => { return this.api.tag.bulkReplace(input, this.userToken) }, 
        replace: (id: string, input: Tag): Promise<Tag> => { return this.api.tag.replace(id, input, this.userToken) }, 
        bulkModify: (input: MassModification<Tag>): Promise<number> => { return this.api.tag.bulkModify(input, this.userToken) }, 
        modifyWithDiff: (id: string, input: Modification<Tag>): Promise<EntryChange<Tag>> => { return this.api.tag.modifyWithDiff(id, input, this.userToken) }, 
        modify: (id: string, input: Modification<Tag>): Promise<Tag> => { return this.api.tag.modify(id, input, this.userToken) }, 
        bulkDelete: (input: Condition<Tag>): Promise<number> => { return this.api.tag.bulkDelete(input, this.userToken) }, 
        delete: (id: string): Promise<void> => { return this.api.tag.delete(id, this.userToken) }, 
        count: (input: Condition<Tag>): Promise<number> => { return this.api.tag.count(input, this.userToken) }, 
        groupCount: (input: GroupCountQuery<Tag>): Promise<Record<string, number>> => { return this.api.tag.groupCount(input, this.userToken) }, 
        aggregate: (input: AggregateQuery<Tag>): Promise<number | null | undefined> => { return this.api.tag.aggregate(input, this.userToken) }, 
        groupAggregate: (input: GroupAggregateQuery<Tag>): Promise<Record<string, number | null | undefined>> => { return this.api.tag.groupAggregate(input, this.userToken) }, 
    }
    readonly blogPost = {
        default: (): Promise<BlogPost> => { return this.api.blogPost.default(this.userToken) }, 
        query: (input: Query<BlogPost>): Promise<Array<BlogPost>> => { return this.api.blogPost.query(input, this.userToken) }, 
        detail: (id: string): Promise<BlogPost> => { return this.api.blogPost.detail(id, this.userToken) }, 
        insertBulk: (input: Array<BlogPost>): Promise<Array<BlogPost>> => { return this.api.blogPost.insertBulk(input, this.userToken) }, 
        insert: (input: BlogPost): Promise<BlogPost> => { return this.api.blogPost.insert(input, this.userToken) }, 
        upsert: (id: string, input: BlogPost): Promise<BlogPost> => { return this.api.blogPost.upsert(id, input, this.userToken) }, 
        bulkReplace: (input: Array<BlogPost>): Promise<Array<BlogPost>> => { return this.api.blogPost.bulkReplace(input, this.userToken) }, 
        replace: (id: string, input: BlogPost): Promise<BlogPost> => { return this.api.blogPost.replace(id, input, this.userToken) }, 
        bulkModify: (input: MassModification<BlogPost>): Promise<number> => { return this.api.blogPost.bulkModify(input, this.userToken) }, 
        modifyWithDiff: (id: string, input: Modification<BlogPost>): Promise<EntryChange<BlogPost>> => { return this.api.blogPost.modifyWithDiff(id, input, this.userToken) }, 
        modify: (id: string, input: Modification<BlogPost>): Promise<BlogPost> => { return this.api.blogPost.modify(id, input, this.userToken) }, 
        bulkDelete: (input: Condition<BlogPost>): Promise<number> => { return this.api.blogPost.bulkDelete(input, this.userToken) }, 
        delete: (id: string): Promise<void> => { return this.api.blogPost.delete(id, this.userToken) }, 
        count: (input: Condition<BlogPost>): Promise<number> => { return this.api.blogPost.count(input, this.userToken) }, 
        groupCount: (input: GroupCountQuery<BlogPost>): Promise<Record<string, number>> => { return this.api.blogPost.groupCount(input, this.userToken) }, 
        aggregate: (input: AggregateQuery<BlogPost>): Promise<number | null | undefined> => { return this.api.blogPost.aggregate(input, this.userToken) }, 
        groupAggregate: (input: GroupAggregateQuery<BlogPost>): Promise<Record<string, number | null | undefined>> => { return this.api.blogPost.groupAggregate(input, this.userToken) }, 
    }
    readonly portFolioEntry = {
        default: (): Promise<PortFolioEntry> => { return this.api.portFolioEntry.default(this.userToken) }, 
        query: (input: Query<PortFolioEntry>): Promise<Array<PortFolioEntry>> => { return this.api.portFolioEntry.query(input, this.userToken) }, 
        detail: (id: string): Promise<PortFolioEntry> => { return this.api.portFolioEntry.detail(id, this.userToken) }, 
        insertBulk: (input: Array<PortFolioEntry>): Promise<Array<PortFolioEntry>> => { return this.api.portFolioEntry.insertBulk(input, this.userToken) }, 
        insert: (input: PortFolioEntry): Promise<PortFolioEntry> => { return this.api.portFolioEntry.insert(input, this.userToken) }, 
        upsert: (id: string, input: PortFolioEntry): Promise<PortFolioEntry> => { return this.api.portFolioEntry.upsert(id, input, this.userToken) }, 
        bulkReplace: (input: Array<PortFolioEntry>): Promise<Array<PortFolioEntry>> => { return this.api.portFolioEntry.bulkReplace(input, this.userToken) }, 
        replace: (id: string, input: PortFolioEntry): Promise<PortFolioEntry> => { return this.api.portFolioEntry.replace(id, input, this.userToken) }, 
        bulkModify: (input: MassModification<PortFolioEntry>): Promise<number> => { return this.api.portFolioEntry.bulkModify(input, this.userToken) }, 
        modifyWithDiff: (id: string, input: Modification<PortFolioEntry>): Promise<EntryChange<PortFolioEntry>> => { return this.api.portFolioEntry.modifyWithDiff(id, input, this.userToken) }, 
        modify: (id: string, input: Modification<PortFolioEntry>): Promise<PortFolioEntry> => { return this.api.portFolioEntry.modify(id, input, this.userToken) }, 
        bulkDelete: (input: Condition<PortFolioEntry>): Promise<number> => { return this.api.portFolioEntry.bulkDelete(input, this.userToken) }, 
        delete: (id: string): Promise<void> => { return this.api.portFolioEntry.delete(id, this.userToken) }, 
        count: (input: Condition<PortFolioEntry>): Promise<number> => { return this.api.portFolioEntry.count(input, this.userToken) }, 
        groupCount: (input: GroupCountQuery<PortFolioEntry>): Promise<Record<string, number>> => { return this.api.portFolioEntry.groupCount(input, this.userToken) }, 
        aggregate: (input: AggregateQuery<PortFolioEntry>): Promise<number | null | undefined> => { return this.api.portFolioEntry.aggregate(input, this.userToken) }, 
        groupAggregate: (input: GroupAggregateQuery<PortFolioEntry>): Promise<Record<string, number | null | undefined>> => { return this.api.portFolioEntry.groupAggregate(input, this.userToken) }, 
    }
    readonly contactSubmission = {
        default: (): Promise<ContactSubmission> => { return this.api.contactSubmission.default(this.userToken) }, 
        query: (input: Query<ContactSubmission>): Promise<Array<ContactSubmission>> => { return this.api.contactSubmission.query(input, this.userToken) }, 
        detail: (id: string): Promise<ContactSubmission> => { return this.api.contactSubmission.detail(id, this.userToken) }, 
        insertBulk: (input: Array<ContactSubmission>): Promise<Array<ContactSubmission>> => { return this.api.contactSubmission.insertBulk(input, this.userToken) }, 
        insert: (input: ContactSubmission): Promise<ContactSubmission> => { return this.api.contactSubmission.insert(input, this.userToken) }, 
        upsert: (id: string, input: ContactSubmission): Promise<ContactSubmission> => { return this.api.contactSubmission.upsert(id, input, this.userToken) }, 
        bulkReplace: (input: Array<ContactSubmission>): Promise<Array<ContactSubmission>> => { return this.api.contactSubmission.bulkReplace(input, this.userToken) }, 
        replace: (id: string, input: ContactSubmission): Promise<ContactSubmission> => { return this.api.contactSubmission.replace(id, input, this.userToken) }, 
        bulkModify: (input: MassModification<ContactSubmission>): Promise<number> => { return this.api.contactSubmission.bulkModify(input, this.userToken) }, 
        modifyWithDiff: (id: string, input: Modification<ContactSubmission>): Promise<EntryChange<ContactSubmission>> => { return this.api.contactSubmission.modifyWithDiff(id, input, this.userToken) }, 
        modify: (id: string, input: Modification<ContactSubmission>): Promise<ContactSubmission> => { return this.api.contactSubmission.modify(id, input, this.userToken) }, 
        bulkDelete: (input: Condition<ContactSubmission>): Promise<number> => { return this.api.contactSubmission.bulkDelete(input, this.userToken) }, 
        delete: (id: string): Promise<void> => { return this.api.contactSubmission.delete(id, this.userToken) }, 
        count: (input: Condition<ContactSubmission>): Promise<number> => { return this.api.contactSubmission.count(input, this.userToken) }, 
        groupCount: (input: GroupCountQuery<ContactSubmission>): Promise<Record<string, number>> => { return this.api.contactSubmission.groupCount(input, this.userToken) }, 
        aggregate: (input: AggregateQuery<ContactSubmission>): Promise<number | null | undefined> => { return this.api.contactSubmission.aggregate(input, this.userToken) }, 
        groupAggregate: (input: GroupAggregateQuery<ContactSubmission>): Promise<Record<string, number | null | undefined>> => { return this.api.contactSubmission.groupAggregate(input, this.userToken) }, 
    }
    readonly pressRelease = {
        default: (): Promise<PressRelease> => { return this.api.pressRelease.default(this.userToken) }, 
        query: (input: Query<PressRelease>): Promise<Array<PressRelease>> => { return this.api.pressRelease.query(input, this.userToken) }, 
        detail: (id: string): Promise<PressRelease> => { return this.api.pressRelease.detail(id, this.userToken) }, 
        insertBulk: (input: Array<PressRelease>): Promise<Array<PressRelease>> => { return this.api.pressRelease.insertBulk(input, this.userToken) }, 
        insert: (input: PressRelease): Promise<PressRelease> => { return this.api.pressRelease.insert(input, this.userToken) }, 
        upsert: (id: string, input: PressRelease): Promise<PressRelease> => { return this.api.pressRelease.upsert(id, input, this.userToken) }, 
        bulkReplace: (input: Array<PressRelease>): Promise<Array<PressRelease>> => { return this.api.pressRelease.bulkReplace(input, this.userToken) }, 
        replace: (id: string, input: PressRelease): Promise<PressRelease> => { return this.api.pressRelease.replace(id, input, this.userToken) }, 
        bulkModify: (input: MassModification<PressRelease>): Promise<number> => { return this.api.pressRelease.bulkModify(input, this.userToken) }, 
        modifyWithDiff: (id: string, input: Modification<PressRelease>): Promise<EntryChange<PressRelease>> => { return this.api.pressRelease.modifyWithDiff(id, input, this.userToken) }, 
        modify: (id: string, input: Modification<PressRelease>): Promise<PressRelease> => { return this.api.pressRelease.modify(id, input, this.userToken) }, 
        bulkDelete: (input: Condition<PressRelease>): Promise<number> => { return this.api.pressRelease.bulkDelete(input, this.userToken) }, 
        delete: (id: string): Promise<void> => { return this.api.pressRelease.delete(id, this.userToken) }, 
        count: (input: Condition<PressRelease>): Promise<number> => { return this.api.pressRelease.count(input, this.userToken) }, 
        groupCount: (input: GroupCountQuery<PressRelease>): Promise<Record<string, number>> => { return this.api.pressRelease.groupCount(input, this.userToken) }, 
        aggregate: (input: AggregateQuery<PressRelease>): Promise<number | null | undefined> => { return this.api.pressRelease.aggregate(input, this.userToken) }, 
        groupAggregate: (input: GroupAggregateQuery<PressRelease>): Promise<Record<string, number | null | undefined>> => { return this.api.pressRelease.groupAggregate(input, this.userToken) }, 
    }
}




export class LiveApi implements Api {
    public constructor(public httpUrl: string, public socketUrl: string = httpUrl, public extraHeaders: Record<string, string> = {}) {}
    uploadFileForRequest(): Promise<UploadInformation> {
        return apiCall(`${this.httpUrl}/upload-early`, undefined, {
            method: "GET",
            }, 
        ).then(x => x.json())
    }
    getServerHealth(userToken: string): Promise<ServerHealth> {
        return apiCall(`${this.httpUrl}/meta/health`, undefined, {
            method: "GET",
            headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
        ).then(x => x.json())
    }
    readonly auth = {
        refreshToken: (userToken: string): Promise<string> => {
            return apiCall(`${this.httpUrl}/auth/refresh-token`, undefined, {
                method: "GET",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        getSelf: (userToken: string): Promise<User> => {
            return apiCall(`${this.httpUrl}/auth/self`, undefined, {
                method: "GET",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        emailLoginLink: (input: string): Promise<void> => {
            return apiCall(`${this.httpUrl}/auth/login-email`, input, {
                method: "POST",
            }, 
            ).then(x => undefined)
        },
    }
    readonly product = {
        default: (userToken?: string): Promise<Product> => {
            return apiCall(`${this.httpUrl}/product/rest/_default_`, undefined, {
                method: "GET",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        query: (input: Query<Product>, userToken?: string): Promise<Array<Product>> => {
            return apiCall(`${this.httpUrl}/product/rest/query`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        detail: (id: string, userToken?: string): Promise<Product> => {
            return apiCall(`${this.httpUrl}/product/rest/${id}`, undefined, {
                method: "GET",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        insertBulk: (input: Array<Product>, userToken?: string): Promise<Array<Product>> => {
            return apiCall(`${this.httpUrl}/product/rest/bulk`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        insert: (input: Product, userToken?: string): Promise<Product> => {
            return apiCall(`${this.httpUrl}/product/rest`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        upsert: (id: string, input: Product, userToken?: string): Promise<Product> => {
            return apiCall(`${this.httpUrl}/product/rest/${id}`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        bulkReplace: (input: Array<Product>, userToken?: string): Promise<Array<Product>> => {
            return apiCall(`${this.httpUrl}/product/rest`, input, {
                method: "PUT",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        replace: (id: string, input: Product, userToken?: string): Promise<Product> => {
            return apiCall(`${this.httpUrl}/product/rest/${id}`, input, {
                method: "PUT",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        bulkModify: (input: MassModification<Product>, userToken?: string): Promise<number> => {
            return apiCall(`${this.httpUrl}/product/rest/bulk`, input, {
                method: "PATCH",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        modifyWithDiff: (id: string, input: Modification<Product>, userToken?: string): Promise<EntryChange<Product>> => {
            return apiCall(`${this.httpUrl}/product/rest/${id}/delta`, input, {
                method: "PATCH",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        modify: (id: string, input: Modification<Product>, userToken?: string): Promise<Product> => {
            return apiCall(`${this.httpUrl}/product/rest/${id}`, input, {
                method: "PATCH",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        bulkDelete: (input: Condition<Product>, userToken?: string): Promise<number> => {
            return apiCall(`${this.httpUrl}/product/rest/bulk-delete`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        delete: (id: string, userToken?: string): Promise<void> => {
            return apiCall(`${this.httpUrl}/product/rest/${id}`, undefined, {
                method: "DELETE",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => undefined)
        },
        count: (input: Condition<Product>, userToken?: string): Promise<number> => {
            return apiCall(`${this.httpUrl}/product/rest/count`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        groupCount: (input: GroupCountQuery<Product>, userToken?: string): Promise<Record<string, number>> => {
            return apiCall(`${this.httpUrl}/product/rest/group-count`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        aggregate: (input: AggregateQuery<Product>, userToken?: string): Promise<number | null | undefined> => {
            return apiCall(`${this.httpUrl}/product/rest/aggregate`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        groupAggregate: (input: GroupAggregateQuery<Product>, userToken?: string): Promise<Record<string, number | null | undefined>> => {
            return apiCall(`${this.httpUrl}/product/rest/group-aggregate`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
    }
    readonly user = {
        default: (userToken?: string): Promise<User> => {
            return apiCall(`${this.httpUrl}/user/rest/_default_`, undefined, {
                method: "GET",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        query: (input: Query<User>, userToken?: string): Promise<Array<User>> => {
            return apiCall(`${this.httpUrl}/user/rest/query`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        detail: (id: string, userToken?: string): Promise<User> => {
            return apiCall(`${this.httpUrl}/user/rest/${id}`, undefined, {
                method: "GET",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        insertBulk: (input: Array<User>, userToken?: string): Promise<Array<User>> => {
            return apiCall(`${this.httpUrl}/user/rest/bulk`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        insert: (input: User, userToken?: string): Promise<User> => {
            return apiCall(`${this.httpUrl}/user/rest`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        upsert: (id: string, input: User, userToken?: string): Promise<User> => {
            return apiCall(`${this.httpUrl}/user/rest/${id}`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        bulkReplace: (input: Array<User>, userToken?: string): Promise<Array<User>> => {
            return apiCall(`${this.httpUrl}/user/rest`, input, {
                method: "PUT",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        replace: (id: string, input: User, userToken?: string): Promise<User> => {
            return apiCall(`${this.httpUrl}/user/rest/${id}`, input, {
                method: "PUT",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        bulkModify: (input: MassModification<User>, userToken?: string): Promise<number> => {
            return apiCall(`${this.httpUrl}/user/rest/bulk`, input, {
                method: "PATCH",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        modifyWithDiff: (id: string, input: Modification<User>, userToken?: string): Promise<EntryChange<User>> => {
            return apiCall(`${this.httpUrl}/user/rest/${id}/delta`, input, {
                method: "PATCH",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        modify: (id: string, input: Modification<User>, userToken?: string): Promise<User> => {
            return apiCall(`${this.httpUrl}/user/rest/${id}`, input, {
                method: "PATCH",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        bulkDelete: (input: Condition<User>, userToken?: string): Promise<number> => {
            return apiCall(`${this.httpUrl}/user/rest/bulk-delete`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        delete: (id: string, userToken?: string): Promise<void> => {
            return apiCall(`${this.httpUrl}/user/rest/${id}`, undefined, {
                method: "DELETE",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => undefined)
        },
        count: (input: Condition<User>, userToken?: string): Promise<number> => {
            return apiCall(`${this.httpUrl}/user/rest/count`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        groupCount: (input: GroupCountQuery<User>, userToken?: string): Promise<Record<string, number>> => {
            return apiCall(`${this.httpUrl}/user/rest/group-count`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        aggregate: (input: AggregateQuery<User>, userToken?: string): Promise<number | null | undefined> => {
            return apiCall(`${this.httpUrl}/user/rest/aggregate`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        groupAggregate: (input: GroupAggregateQuery<User>, userToken?: string): Promise<Record<string, number | null | undefined>> => {
            return apiCall(`${this.httpUrl}/user/rest/group-aggregate`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
    }
    readonly tag = {
        default: (userToken?: string): Promise<Tag> => {
            return apiCall(`${this.httpUrl}/collections/rest/_default_`, undefined, {
                method: "GET",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        query: (input: Query<Tag>, userToken?: string): Promise<Array<Tag>> => {
            return apiCall(`${this.httpUrl}/collections/rest/query`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        detail: (id: string, userToken?: string): Promise<Tag> => {
            return apiCall(`${this.httpUrl}/collections/rest/${id}`, undefined, {
                method: "GET",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        insertBulk: (input: Array<Tag>, userToken?: string): Promise<Array<Tag>> => {
            return apiCall(`${this.httpUrl}/collections/rest/bulk`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        insert: (input: Tag, userToken?: string): Promise<Tag> => {
            return apiCall(`${this.httpUrl}/collections/rest`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        upsert: (id: string, input: Tag, userToken?: string): Promise<Tag> => {
            return apiCall(`${this.httpUrl}/collections/rest/${id}`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        bulkReplace: (input: Array<Tag>, userToken?: string): Promise<Array<Tag>> => {
            return apiCall(`${this.httpUrl}/collections/rest`, input, {
                method: "PUT",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        replace: (id: string, input: Tag, userToken?: string): Promise<Tag> => {
            return apiCall(`${this.httpUrl}/collections/rest/${id}`, input, {
                method: "PUT",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        bulkModify: (input: MassModification<Tag>, userToken?: string): Promise<number> => {
            return apiCall(`${this.httpUrl}/collections/rest/bulk`, input, {
                method: "PATCH",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        modifyWithDiff: (id: string, input: Modification<Tag>, userToken?: string): Promise<EntryChange<Tag>> => {
            return apiCall(`${this.httpUrl}/collections/rest/${id}/delta`, input, {
                method: "PATCH",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        modify: (id: string, input: Modification<Tag>, userToken?: string): Promise<Tag> => {
            return apiCall(`${this.httpUrl}/collections/rest/${id}`, input, {
                method: "PATCH",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        bulkDelete: (input: Condition<Tag>, userToken?: string): Promise<number> => {
            return apiCall(`${this.httpUrl}/collections/rest/bulk-delete`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        delete: (id: string, userToken?: string): Promise<void> => {
            return apiCall(`${this.httpUrl}/collections/rest/${id}`, undefined, {
                method: "DELETE",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => undefined)
        },
        count: (input: Condition<Tag>, userToken?: string): Promise<number> => {
            return apiCall(`${this.httpUrl}/collections/rest/count`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        groupCount: (input: GroupCountQuery<Tag>, userToken?: string): Promise<Record<string, number>> => {
            return apiCall(`${this.httpUrl}/collections/rest/group-count`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        aggregate: (input: AggregateQuery<Tag>, userToken?: string): Promise<number | null | undefined> => {
            return apiCall(`${this.httpUrl}/collections/rest/aggregate`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        groupAggregate: (input: GroupAggregateQuery<Tag>, userToken?: string): Promise<Record<string, number | null | undefined>> => {
            return apiCall(`${this.httpUrl}/collections/rest/group-aggregate`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
    }
    readonly blogPost = {
        default: (userToken?: string): Promise<BlogPost> => {
            return apiCall(`${this.httpUrl}/blog/rest/_default_`, undefined, {
                method: "GET",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        query: (input: Query<BlogPost>, userToken?: string): Promise<Array<BlogPost>> => {
            return apiCall(`${this.httpUrl}/blog/rest/query`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        detail: (id: string, userToken?: string): Promise<BlogPost> => {
            return apiCall(`${this.httpUrl}/blog/rest/${id}`, undefined, {
                method: "GET",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        insertBulk: (input: Array<BlogPost>, userToken?: string): Promise<Array<BlogPost>> => {
            return apiCall(`${this.httpUrl}/blog/rest/bulk`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        insert: (input: BlogPost, userToken?: string): Promise<BlogPost> => {
            return apiCall(`${this.httpUrl}/blog/rest`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        upsert: (id: string, input: BlogPost, userToken?: string): Promise<BlogPost> => {
            return apiCall(`${this.httpUrl}/blog/rest/${id}`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        bulkReplace: (input: Array<BlogPost>, userToken?: string): Promise<Array<BlogPost>> => {
            return apiCall(`${this.httpUrl}/blog/rest`, input, {
                method: "PUT",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        replace: (id: string, input: BlogPost, userToken?: string): Promise<BlogPost> => {
            return apiCall(`${this.httpUrl}/blog/rest/${id}`, input, {
                method: "PUT",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        bulkModify: (input: MassModification<BlogPost>, userToken?: string): Promise<number> => {
            return apiCall(`${this.httpUrl}/blog/rest/bulk`, input, {
                method: "PATCH",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        modifyWithDiff: (id: string, input: Modification<BlogPost>, userToken?: string): Promise<EntryChange<BlogPost>> => {
            return apiCall(`${this.httpUrl}/blog/rest/${id}/delta`, input, {
                method: "PATCH",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        modify: (id: string, input: Modification<BlogPost>, userToken?: string): Promise<BlogPost> => {
            return apiCall(`${this.httpUrl}/blog/rest/${id}`, input, {
                method: "PATCH",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        bulkDelete: (input: Condition<BlogPost>, userToken?: string): Promise<number> => {
            return apiCall(`${this.httpUrl}/blog/rest/bulk-delete`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        delete: (id: string, userToken?: string): Promise<void> => {
            return apiCall(`${this.httpUrl}/blog/rest/${id}`, undefined, {
                method: "DELETE",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => undefined)
        },
        count: (input: Condition<BlogPost>, userToken?: string): Promise<number> => {
            return apiCall(`${this.httpUrl}/blog/rest/count`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        groupCount: (input: GroupCountQuery<BlogPost>, userToken?: string): Promise<Record<string, number>> => {
            return apiCall(`${this.httpUrl}/blog/rest/group-count`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        aggregate: (input: AggregateQuery<BlogPost>, userToken?: string): Promise<number | null | undefined> => {
            return apiCall(`${this.httpUrl}/blog/rest/aggregate`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        groupAggregate: (input: GroupAggregateQuery<BlogPost>, userToken?: string): Promise<Record<string, number | null | undefined>> => {
            return apiCall(`${this.httpUrl}/blog/rest/group-aggregate`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
    }
    readonly portFolioEntry = {
        default: (userToken?: string): Promise<PortFolioEntry> => {
            return apiCall(`${this.httpUrl}/portfolio/rest/_default_`, undefined, {
                method: "GET",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        query: (input: Query<PortFolioEntry>, userToken?: string): Promise<Array<PortFolioEntry>> => {
            return apiCall(`${this.httpUrl}/portfolio/rest/query`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        detail: (id: string, userToken?: string): Promise<PortFolioEntry> => {
            return apiCall(`${this.httpUrl}/portfolio/rest/${id}`, undefined, {
                method: "GET",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        insertBulk: (input: Array<PortFolioEntry>, userToken?: string): Promise<Array<PortFolioEntry>> => {
            return apiCall(`${this.httpUrl}/portfolio/rest/bulk`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        insert: (input: PortFolioEntry, userToken?: string): Promise<PortFolioEntry> => {
            return apiCall(`${this.httpUrl}/portfolio/rest`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        upsert: (id: string, input: PortFolioEntry, userToken?: string): Promise<PortFolioEntry> => {
            return apiCall(`${this.httpUrl}/portfolio/rest/${id}`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        bulkReplace: (input: Array<PortFolioEntry>, userToken?: string): Promise<Array<PortFolioEntry>> => {
            return apiCall(`${this.httpUrl}/portfolio/rest`, input, {
                method: "PUT",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        replace: (id: string, input: PortFolioEntry, userToken?: string): Promise<PortFolioEntry> => {
            return apiCall(`${this.httpUrl}/portfolio/rest/${id}`, input, {
                method: "PUT",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        bulkModify: (input: MassModification<PortFolioEntry>, userToken?: string): Promise<number> => {
            return apiCall(`${this.httpUrl}/portfolio/rest/bulk`, input, {
                method: "PATCH",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        modifyWithDiff: (id: string, input: Modification<PortFolioEntry>, userToken?: string): Promise<EntryChange<PortFolioEntry>> => {
            return apiCall(`${this.httpUrl}/portfolio/rest/${id}/delta`, input, {
                method: "PATCH",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        modify: (id: string, input: Modification<PortFolioEntry>, userToken?: string): Promise<PortFolioEntry> => {
            return apiCall(`${this.httpUrl}/portfolio/rest/${id}`, input, {
                method: "PATCH",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        bulkDelete: (input: Condition<PortFolioEntry>, userToken?: string): Promise<number> => {
            return apiCall(`${this.httpUrl}/portfolio/rest/bulk-delete`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        delete: (id: string, userToken?: string): Promise<void> => {
            return apiCall(`${this.httpUrl}/portfolio/rest/${id}`, undefined, {
                method: "DELETE",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => undefined)
        },
        count: (input: Condition<PortFolioEntry>, userToken?: string): Promise<number> => {
            return apiCall(`${this.httpUrl}/portfolio/rest/count`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        groupCount: (input: GroupCountQuery<PortFolioEntry>, userToken?: string): Promise<Record<string, number>> => {
            return apiCall(`${this.httpUrl}/portfolio/rest/group-count`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        aggregate: (input: AggregateQuery<PortFolioEntry>, userToken?: string): Promise<number | null | undefined> => {
            return apiCall(`${this.httpUrl}/portfolio/rest/aggregate`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        groupAggregate: (input: GroupAggregateQuery<PortFolioEntry>, userToken?: string): Promise<Record<string, number | null | undefined>> => {
            return apiCall(`${this.httpUrl}/portfolio/rest/group-aggregate`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
    }
    readonly contactSubmission = {
        default: (userToken?: string): Promise<ContactSubmission> => {
            return apiCall(`${this.httpUrl}/contact-us/rest/_default_`, undefined, {
                method: "GET",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        query: (input: Query<ContactSubmission>, userToken?: string): Promise<Array<ContactSubmission>> => {
            return apiCall(`${this.httpUrl}/contact-us/rest/query`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        detail: (id: string, userToken?: string): Promise<ContactSubmission> => {
            return apiCall(`${this.httpUrl}/contact-us/rest/${id}`, undefined, {
                method: "GET",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        insertBulk: (input: Array<ContactSubmission>, userToken?: string): Promise<Array<ContactSubmission>> => {
            return apiCall(`${this.httpUrl}/contact-us/rest/bulk`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        insert: (input: ContactSubmission, userToken?: string): Promise<ContactSubmission> => {
            return apiCall(`${this.httpUrl}/contact-us/rest`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        upsert: (id: string, input: ContactSubmission, userToken?: string): Promise<ContactSubmission> => {
            return apiCall(`${this.httpUrl}/contact-us/rest/${id}`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        bulkReplace: (input: Array<ContactSubmission>, userToken?: string): Promise<Array<ContactSubmission>> => {
            return apiCall(`${this.httpUrl}/contact-us/rest`, input, {
                method: "PUT",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        replace: (id: string, input: ContactSubmission, userToken?: string): Promise<ContactSubmission> => {
            return apiCall(`${this.httpUrl}/contact-us/rest/${id}`, input, {
                method: "PUT",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        bulkModify: (input: MassModification<ContactSubmission>, userToken?: string): Promise<number> => {
            return apiCall(`${this.httpUrl}/contact-us/rest/bulk`, input, {
                method: "PATCH",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        modifyWithDiff: (id: string, input: Modification<ContactSubmission>, userToken?: string): Promise<EntryChange<ContactSubmission>> => {
            return apiCall(`${this.httpUrl}/contact-us/rest/${id}/delta`, input, {
                method: "PATCH",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        modify: (id: string, input: Modification<ContactSubmission>, userToken?: string): Promise<ContactSubmission> => {
            return apiCall(`${this.httpUrl}/contact-us/rest/${id}`, input, {
                method: "PATCH",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        bulkDelete: (input: Condition<ContactSubmission>, userToken?: string): Promise<number> => {
            return apiCall(`${this.httpUrl}/contact-us/rest/bulk-delete`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        delete: (id: string, userToken?: string): Promise<void> => {
            return apiCall(`${this.httpUrl}/contact-us/rest/${id}`, undefined, {
                method: "DELETE",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => undefined)
        },
        count: (input: Condition<ContactSubmission>, userToken?: string): Promise<number> => {
            return apiCall(`${this.httpUrl}/contact-us/rest/count`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        groupCount: (input: GroupCountQuery<ContactSubmission>, userToken?: string): Promise<Record<string, number>> => {
            return apiCall(`${this.httpUrl}/contact-us/rest/group-count`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        aggregate: (input: AggregateQuery<ContactSubmission>, userToken?: string): Promise<number | null | undefined> => {
            return apiCall(`${this.httpUrl}/contact-us/rest/aggregate`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        groupAggregate: (input: GroupAggregateQuery<ContactSubmission>, userToken?: string): Promise<Record<string, number | null | undefined>> => {
            return apiCall(`${this.httpUrl}/contact-us/rest/group-aggregate`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
    }
    readonly pressRelease = {
        default: (userToken?: string): Promise<PressRelease> => {
            return apiCall(`${this.httpUrl}/press/rest/_default_`, undefined, {
                method: "GET",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        query: (input: Query<PressRelease>, userToken?: string): Promise<Array<PressRelease>> => {
            return apiCall(`${this.httpUrl}/press/rest/query`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        detail: (id: string, userToken?: string): Promise<PressRelease> => {
            return apiCall(`${this.httpUrl}/press/rest/${id}`, undefined, {
                method: "GET",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        insertBulk: (input: Array<PressRelease>, userToken?: string): Promise<Array<PressRelease>> => {
            return apiCall(`${this.httpUrl}/press/rest/bulk`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        insert: (input: PressRelease, userToken?: string): Promise<PressRelease> => {
            return apiCall(`${this.httpUrl}/press/rest`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        upsert: (id: string, input: PressRelease, userToken?: string): Promise<PressRelease> => {
            return apiCall(`${this.httpUrl}/press/rest/${id}`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        bulkReplace: (input: Array<PressRelease>, userToken?: string): Promise<Array<PressRelease>> => {
            return apiCall(`${this.httpUrl}/press/rest`, input, {
                method: "PUT",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        replace: (id: string, input: PressRelease, userToken?: string): Promise<PressRelease> => {
            return apiCall(`${this.httpUrl}/press/rest/${id}`, input, {
                method: "PUT",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        bulkModify: (input: MassModification<PressRelease>, userToken?: string): Promise<number> => {
            return apiCall(`${this.httpUrl}/press/rest/bulk`, input, {
                method: "PATCH",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        modifyWithDiff: (id: string, input: Modification<PressRelease>, userToken?: string): Promise<EntryChange<PressRelease>> => {
            return apiCall(`${this.httpUrl}/press/rest/${id}/delta`, input, {
                method: "PATCH",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        modify: (id: string, input: Modification<PressRelease>, userToken?: string): Promise<PressRelease> => {
            return apiCall(`${this.httpUrl}/press/rest/${id}`, input, {
                method: "PATCH",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        bulkDelete: (input: Condition<PressRelease>, userToken?: string): Promise<number> => {
            return apiCall(`${this.httpUrl}/press/rest/bulk-delete`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        delete: (id: string, userToken?: string): Promise<void> => {
            return apiCall(`${this.httpUrl}/press/rest/${id}`, undefined, {
                method: "DELETE",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => undefined)
        },
        count: (input: Condition<PressRelease>, userToken?: string): Promise<number> => {
            return apiCall(`${this.httpUrl}/press/rest/count`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        groupCount: (input: GroupCountQuery<PressRelease>, userToken?: string): Promise<Record<string, number>> => {
            return apiCall(`${this.httpUrl}/press/rest/group-count`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        aggregate: (input: AggregateQuery<PressRelease>, userToken?: string): Promise<number | null | undefined> => {
            return apiCall(`${this.httpUrl}/press/rest/aggregate`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        groupAggregate: (input: GroupAggregateQuery<PressRelease>, userToken?: string): Promise<Record<string, number | null | undefined>> => {
            return apiCall(`${this.httpUrl}/press/rest/group-aggregate`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
    }
}

